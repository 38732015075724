import { graphql } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { ModalContext } from '../components/context/ModalContext'
import styled from 'styled-components'
import { Button } from '@monbanquet/crumble'
import LargeBanner, { StyledLargeBanner } from '../components/LargeBanner'
import CatererList from '../components/CatererList'
import { breakpoints, layout } from '../theme'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { groupBy, sortBy, mapValues } from 'lodash'
import Helmet from 'react-helmet'
import { getQueryParams, makeQueryString } from '../util'
import Link from '../components/Link'

const ParisPage = ({ navigate, ...props }) => {
  const otherPages = {}
  props.data.otherPages.edges.forEach(
    ({ node: page }) => (otherPages[page.postalCode] = page.path),
  )
  useEffect(() => {
    // apply queryString with utms set to the referrer when not coming from the website itself
    // useful to know which lead comes to this page from organic search
    if (
      document.referrer.length > 0 &&
      !document.referrer.startsWith(process.env.GATSBY_URL)
    ) {
      const qs = makeQueryString({
        utm_source: 'seo',
        utm_campaign: 'traiteurs-paris',
        utm_medium: (document.referrer || '').match(/:\/\/(.[^/]+)/)[1],
        ...getQueryParams(), // don't overwrite existing query params
      })
      navigate(qs, { replace: true })
    }
  }, [])

  const city = props.data.city
  const caters = city.caters
  const caterersByDistricts = mapValues(groupBy(caters, 'zip'), c =>
    sortBy(c, c => c),
  )
  const districtSeparators = city.separators
  const { dispatch: dispatchModal } = useContext(ModalContext)
  const summary = { 0: [], 1: [], 2: [], 3: [], 4: [] }
  const caterList = []

  Object.keys(caterersByDistricts).map((district, index) => {
    const districtData = districtSeparators.find(
      value => value.zip === district,
    )
    const summaryColumn = Math.floor(index / 5)

    if (!districtData || districtData.length == 0) {
      console.warn('Error to match ' + district + ' ' + index)
    } else {
      summary[summaryColumn].push(districtData.title)

      caterList.push(
        <div key={index}>
          <CatererList
            withSeparator={city.withSeparator}
            district={district}
            title={districtData.title}
            description={districtData.description}
            caterers={caterersByDistricts[district]}
          />
        </div>,
      )
    }
  })

  const cityList = city.withSeparator && (
    <div className="summary" id="summary">
      {Object.keys(summary).map((key, index) => {
        return (
          <div key={index} className="summary-column">
            {summary[key].map((value, i) => {
              return (
                <p className="summary-item" key={i}>
                  <Link
                    to={
                      otherPages[
                        Object.keys(caterersByDistricts)[index * 5 + i]
                      ]
                    }
                  >
                    {value}
                  </Link>
                </p>
              )
            })}
          </div>
        )
      })}
    </div>
  )

  return (
    <StyledParisPage>
      <Helmet
        title={city.browserTabTitle}
        meta={[{ name: 'keywords', content: city.metaKeywords }]}
        // set a canonical link to only the bigger variant of a menu to avoid duplicate content
        link={[
          {
            rel: 'canonical',
            href: city.path,
          },
        ]}
      />
      <Navbar childrenOnRight={true}>
        <Button
          className="CTAButton"
          onClick={() => {
            dispatchModal({
              type: 'OPEN',
              analytics: {
                location: 'Caterer',
              },
            })
          }}
        >
          Obtenir un devis
        </Button>
      </Navbar>
      <LargeBanner picture={city.image}>
        {city.withSeparator ? (
          <div>Traiteurs à {city.titlePageName}</div>
        ) : (
          <h1>Traiteurs à {city.titlePageName}</h1>
        )}
        <details className="banner-details">
          <summary>{city.descriptionSummary}</summary>
          {city.descriptionHidden}
        </details>
      </LargeBanner>
      <div className="page-content">
        {cityList}
        {caterList}
        {cityList}
      </div>
      ,
      <Footer />
    </StyledParisPage>
  )
}

const StyledParisPage = styled.div`
  @media (max-width: ${breakpoints.mobile}px) {
    .banner-details {
      font-size: 12px;
    }
  }

  @media (max-width: ${breakpoints.smallMobile}px) {
    .banner-details {
      font-size: 11px;
    }
  }

  .summary {
    padding-top: ${layout.navbarHeight.normal + 10}px;
    margin: auto;
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    display: flex;
    justify-content: space-between;
    .summary-item {
      margin-bottom: 5px;
      cursor: pointer;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .summary-column {
      padding: 5px;
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  .CTAButton {
    margin-left: auto !important;
    height: 40px;
    font-size: 16px;
  }

  ${StyledLargeBanner} {
    margin-top: 70px;
    height: 400px;
  }

  ${StyledLargeBanner} .wrapper {
    margin: 0 auto;
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
  }
`

export const query = graphql`
  query($slug: String!) {
    city: seoCitiesCaters(path: { eq: $slug }) {
      tabName
      postalCode
      jsonFileName
      path
      withSeparator
      titlePageName
      browserTabTitle
      metaKeywords
      coordinates
      image
      descriptionSummary
      descriptionHidden
      defaultCaterImageUrl
      separators {
        title
        description
        zip
      }
      caters {
        name
        artisanMB
        rating
        price
        address
        city
        zip
        country
        description
        imageUrl
      }
    }
    otherPages: allSeoCitiesCaters {
      edges {
        node {
          postalCode
          path
        }
      }
    }
  }
`

export default ParisPage
export { StyledParisPage }
