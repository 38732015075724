import { Button, StyledButton } from '@monbanquet/crumble'
import React from 'react'
import styled from 'styled-components'
import iconCertified from '../assets/icons/certified.svg'
import { breakpoints, colors, transitions } from '../theme'

const star = '★'
const starOutlined = '☆'
const maxRating = 5

function getRating(rating) {
  const stars = Array(maxRating).fill(starOutlined)
  for (let i = 0; i < rating; i++) {
    stars[i] = star
  }
  return stars
}

const Caterer = ({
  name,
  artisanMB,
  rating,
  price,
  address,
  city,
  zip,
  country,
  description,
  imageUrl,
  onCtaClicked = () => {},
}) => (
  <StyledCaterer itemScope itemType="http://schema.org/LocalBusiness">
    {imageUrl && (
      <div className="picture">
        <img
          src={imageUrl}
          style={imgStyle}
          alt={name}
          itemProp="image"
          loading="lazy"
        />
      </div>
    )}
    <div className="main">
      <span>{artisanMB ? <i className="approved" /> : null}</span>
      <div className="details">
        <h2 className="name" itemProp="legalName">
          {name}
        </h2>
        <span className="rating">
          {rating ? (
            <>{getRating(rating).join(' ')}</>
          ) : (
            <>{getRating(0).join(' ')}</>
          )}
        </span>
        <span className="price-range" itemProp="priceRange">
          {price ? (
            <>
              <em>{'€'.repeat(price)}</em>
              {'€€€'.substring(price)}
            </>
          ) : (
            '€€€'
          )}
        </span>
      </div>
      <p
        className="address"
        itemProp="address"
        itemScope
        itemType="http://schema.org/PostalAddress"
      >
        <span itemProp="streetAddress">{address}</span>,
        <span itemProp="addressLocality">&nbsp;{city}</span>,
        <span itemProp="postalCode">&nbsp;{zip}</span>,
        <span itemProp="addressCountry">&nbsp;{country}</span>
      </p>
      {description && <p className="description">{description}</p>}
    </div>
    {artisanMB ? (
      <Button onClick={onCtaClicked}>Obtenir un devis</Button>
    ) : null}
  </StyledCaterer>
)

const imgStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const StyledCaterer = styled.div`
  display: flex;
  padding: 25px 0;

  .picture {
    flex-shrink: 0;
    flex-basis: 170px;
    position: relative;
    width: 170px;
    height: 170px;
    overflow: hidden;
    align-self: center;
    border-radius: 50%;

    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      box-shadow: inset 0 0 10px 0px rgba(70, 70, 70, 0.5);
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0 25px;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
  }
  i.approved {
    margin-left: 7px;
    background: url(${iconCertified}) no-repeat center;
    width: 20px;
    height: 20px;
    position: relative;

    &::after {
      content: 'Traiteur approuvé par Monbanquet';
      position: absolute;
      opacity: 0;
      pointer-events: none;
      color: ${colors.text.light2};
      font-size: 0.85rem;
      font-style: normal;
      white-space: nowrap;
      padding: 5px 10px;
      border: 1px solid #e3e3e3;
      padding-left: 26px;
      border-radius: 50px;
      top: -2px;
      left: -3px;
      transition: ${transitions.all};
    }
    &:hover::after {
      opacity: 1;
      pointer-events: all;
    }
  }
  .details {
    color: ${colors.text.light};
  }
  .name {
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    color: ${colors.text.light};
    margin-bottom: 7px;

    &::after {
      font-size: 0.8rem;
      content: '•';
      margin: 0 7px;
      color: #999;
    }
  }
  .rating {
    font-size: 0.8rem;
    color: ${colors.accent.gold};
    white-space: nowrap;
    &::after {
      font-size: 0.8rem;
      content: '•';
      margin: 0 7px;
      color: #999;
    }
  }
  .price-range {
    font-size: 0.9rem;
    color: #ddd;
    flex-shrink: 0;
    white-space: nowrap;
    em {
      font-style: normal;
      color: ${colors.text.light2};
    }
  }
  .description {
    line-height: 20px;
    margin-top: 7px;
  }
  ${StyledButton} {
    flex-shrink: 0;
    height: 36px;
    letter-spacing: 0.2px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    h3,
    i.approved {
      display: block;
    }
    h4 {
      flex-direction: column;
      align-items: flex-start;
    }
    i.approved {
      margin-top: 5px;
      margin-left: 0;
      &::after {
        opacity: 1;
        pointer-events: all;
      }
    }
    .picture {
      margin-bottom: 15px;
    }
    .description {
      margin: 15px 0;
    }
  }
`

export default Caterer
export { StyledCaterer }
