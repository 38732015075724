import React, { useContext } from 'react'
import styled from 'styled-components'
import { ModalContext } from './context/ModalContext'
import { borders, layout } from '../theme'
import Caterer, { StyledCaterer } from './Caterer'

const CatererList = ({
  withSeparator,
  district,
  title,
  description,
  caterers,
  ...props
}) => {
  const { dispatch: dispatchModal } = useContext(ModalContext)
  let cityName
  return (
    <StyledCatererList {...props}>
      {withSeparator && (
        <>
          {title && (
            <h1 itemProp="addressRegion" id={district}>
              {title}
            </h1>
          )}
          {description && <p>{description}</p>}
        </>
      )}
      <ul>
        {caterers.map((caterer, index) => {
          cityName = caterer.city
          return (
            <Caterer
              key={index}
              as="li"
              {...caterer}
              onCtaClicked={() => {
                dispatchModal({
                  type: 'OPEN',
                  analytics: {
                    location: 'Caterer',
                  },
                })
              }}
            />
          )
        })}
        {withSeparator && (
          <p className="back-to-top">
            <a href="#summary">
              Liste de tous les arrondissements de {cityName}
            </a>
          </p>
        )}
      </ul>
    </StyledCatererList>
  )
}

const StyledCatererList = styled.section`
  > ul,
  > h1,
  > p {
    max-width: ${layout.maxWidth}px;
    width: ${layout.width};
    margin: 0 auto;
  }

  > h1 {
    padding-top: ${layout.navbarHeight.normal + 10}px;
  }

  > p {
    margin: 20px auto;
  }

  ${StyledCaterer}:not (:last-child) {
    border-bottom: 1px solid ${borders.color.light};
  }

  .back-to-top {
    margin: 20px 0;
    display: flex;
    a {
      margin-left: auto;
    }
  }
`

export default CatererList
